.vfg {
    margin-top: 50px;
    text-align: center;
}

.item-details-area>.container {
    background-color: #131920;
    padding: 20px;
    padding-top: 50px;
    margin-top: 90px;
    padding-right: 50px;
    margin-bottom: 150px;
    border-radius: 10px;
}

.qwertyy {
    color: white;
    font-weight: 800;
    padding-bottom: 10px;
}

.qwertyt {
    color: rgb(148, 145, 145);
    font-weight: 400;
    font-size: 16px;
}

.vbh-c {
    background: #0f1318;
    padding: 10px;
}

.item-thumb>img {
    width: 90%;
    border-radius: 10px;
}

.tab-content,
.nav-tabs {
    margin-left: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
}

.rounded-circle {
    width: 40px;
    height: 40px;
}

.vfgg {
    color: rgb(153, 152, 152);
    font-size: 16px;
}

.nav-tabs>li>a {
    color: white;
    transition: 0.3s;
}

.nav-tabs>li>a:hover {
    color: rgb(78, 47, 216);
    transition: 0.3s;
}

.nav-tabs>li>.active {
    color: rgb(78, 47, 216);
    transition: 0.3s;
}

.nav-tabs>li {
    margin-right: 15px;
}

.login-dark {
    background-size: cover;
    position: relative;
}

.intro-content>span {
    color: blue;
    font-size: 17px;
    font-weight: 800;
}

.intro-content>h3 {
    color: rgb(255, 255, 255);
    font-weight: 800;
    margin-top: -10px;
}

.vlc {
    justify-content: center;
}

.item-form {
    width: 100%;
    margin: auto;
    background-color: rgb(23, 20, 29);
    padding: 20px;
    margin-top: -20px;
    border-radius: 10px;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    background: rgb(8, 7, 10);
    font-size: 16px;
    color: #888;
    border: 0px;
    padding: 15px;
    border-radius: 0;
    width: 100%;
}

.form-check-label {
    color: #888;
}

.form-control {
    background: rgb(8, 7, 10);
    font-size: 16px;
    color: #888;
    border: 0;
    border-radius: 0;
}

.custom-file,
.form-control:active,
.form-control::after,
.form-control:hover,
.form-control:focus,
.form-control::before {
    background: rgb(8, 7, 10);
    font-size: 16px;
    color: #888;
    border: 0;
    border-radius: 0;
}

.login-dark form {
    max-width: 320px;
    width: 90%;
    background-color: #1e2833;
    padding: 40px;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}

.tgh {
    width: 100%;
    margin-top: 180px;
    margin-bottom: 250px;
}

.login-dark .illustration {
    text-align: center;
    padding: 15px 0 20px;
    font-size: 100px;
    color: #2980ef;
}

.login-dark form .form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    font-size: 16px;
    box-shadow: none;
    outline: none;
    color: inherit;
}

.login-dark form .btn-primary {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
}

.login-dark form .btn-primary:hover,
.login-dark form .btn-primary:active {
    background: #214a80;
    outline: none;
}

.login-dark form .forgot {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #6f7a85;
    opacity: 0.9;
    text-decoration: none;
}

.login-dark form .forgot:hover,
.login-dark form .forgot:active {
    opacity: 1;
    text-decoration: none;
}

.login-dark form .btn-primary:active {
    transform: translateY(1px);
}

.vl-wallet {
    margin-top: 50px;
}

.vl-wallet>span {
    color: #5000e6;
    font-size: 20px;
    font-weight: 800;
}

.vl-wallet>h3 {
    color: white;
    font-weight: 900;
    padding-bottom: 10px;
}

.vl-wallet>p {
    color: #888;
    font-size: 17px;
    padding-bottom: 50px;
}

.single-wallet {
    margin-bottom: 30px;
    background-color: rgb(21, 22, 31);
    padding: 15px;
    border-radius: 10px;
    transition: 0.3s;
}

.single-wallet:hover {
    margin-bottom: 30px;
    background-color: rgb(33, 34, 48);
    padding: 15px;
    border-radius: 10px;
    transition: 0.3s;
}

.single-wallet>a>h4 {
    color: white;
    padding-bottom: 10px;
    padding-top: 20px;
}

.avatar-lg {
    width: 100px;
    height: 100px;
}

.single-wallet>a>p {
    color: #888;
}

.sec-1 {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sec-1>.card {
    max-width: 250px;
    margin: 15px;
    background-color: #1c2227;
    border-radius: 10px;
}

.vl-img {
    margin-top: -5px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.sec-1>.card>.card-body {
    padding: 0;
    margin-top: 20px;
}

.sec-1>.card>.card-body>a>h5 {
    color: #fff;
    font-weight: 900;
    text-decoration: none;
    transition: 0.3s;
}

a {
    text-decoration: none !important;
}

.sec-1>.card>.card-body>a>h5:hover {
    color: rgb(34, 65, 238);
    font-weight: 900;
    text-decoration: none !important;
    transition: 0.3s;
}

.b-t-l {
    color: white;
    width: 40%;
    float: left;
}

.b-t-r {
    color: white;
    width: 40%;
    float: right;
    text-align: right;
}

.sec-t {
    margin-top: 100px;
    margin-left: 15px;
}

.sec-t>b {
    font-size: 19px;
    color: rgb(50, 39, 202);
}

.sec-t>h2 {
    font-weight: 900;
    color: #fff;
}

.button-vl {
    width: 150px;
    background: transparent;
    border-radius: 50px;
    border: 2px solid #fff;
    transition: 0.3s;
    color: white;
    font-weight: 500;
    padding: 10px;
    font-size: 19px;
}

.button-vl:hover {
    width: 150px;
    background: rgba(76, 24, 219, 0.933);
    border-radius: 50px;
    border: 2px solid rgba(76, 24, 219, 0.933);
    transition: 0.3s;
    color: white;
    font-weight: 500;
    padding: 10px;
    font-size: 19px;
}

.avatar {
    width: 50px;
    height: 50px;
}

.avatar>img {
    width: 100%;
    border-radius: 100px;
}

.avatar-a {
    width: 70px;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #ccc;
    border-radius: 100px;
    box-shadow: 0 0 10px #000;
    margin-top: -50px;
}

.pavatar {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #ccc;
    border-radius: 100px;
    box-shadow: 0 0 10px #000;
    margin-top: -50px;
    justify-content: center;
}

.profile-usertitle-name {
    color: white;
    margin: 10px;
    font-size: 22px;
    font-weight: 700;
}

.rty {
    margin-top: -110px;
}

.a-input {
    border-radius: 50px;
    padding: 10px;
    display: block;
    margin-top: 10px;
    width: 100%;
    background: transparent;
    color: #ccc;
}

.profile-usertitle-job {
    color: rgb(148, 144, 144);
    margin: 10px;
    font-size: 16px;
}

.a-det {
    text-align: center;
}

.a-det>a>h2 {
    color: white;
    font-size: 23px;
    margin-top: 15px;
    margin-bottom: 30px;
    transition: 0.3s;
}

.a-det>a>h2:hover {
    color: rgb(74, 42, 192);
    font-size: 23px;
    margin-top: 15px;
    margin-bottom: 30px;
    transition: 0.3s;
}

.avatar-a>img {
    width: 100%;
    border-radius: 100px;
}

.avatar-text {
    font-size: 16px;
    color: #888;
    display: flex;
    max-width: 150px;
    margin-left: 30px;
    margin-top: -37px;
    background-color: transparent;
    text-decoration: none;
    transition: 0.3s;
    margin-bottom: 10px;
}

.avatar-text:hover {
    font-size: 16px;
    color: #eee;
    transition: 0.3s;
    text-decoration: none;
}

.sec-1>.card>.card-img-top {
    margin-top: 15px;
    border-radius: 10px;
}

.section-down>.card {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    border: 0;
}

.section-down>.card>.card-body {
    padding-left: 0;
    padding-right: 0;
}

.section-down>.card>.card-body>h5 {
    color: #ccc;
    font-weight: 900;
}

.section-down>.card>.card-body>h5>i {
    color: rgb(87, 18, 214);
    font-size: 50px;
}

.section-down>.card>.card-body>p {
    color: rgb(158, 155, 155);
}

.footer-nav>.nav-link:hover {
    padding-left: 5px;
    transition: 0.3s;
}

.footer-nav>.nav-link {
    transition: 0.3s;
}

.lead-social-menu>a {
    background-color: rgb(52, 51, 61);
    padding: 8px;
    border-radius: 5px;
    transition: 0.3s;
}

.user-mob {
    color: white;
    font-size: 20px;
    text-decoration: none;
    margin-right: 15px;
}

.lead-social-menu>a:hover {
    background-color: rgb(75, 74, 88);
    transition: 0.3s;
}

.hero-image {
    background-image: url("/assets/img/header.gif");
    /* Used if the image is unavailable */
    height: 100vh;
    /* You must set a specified height */
    background-position: left;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
}

.hero-image>h1>b {
    font-size: 20px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: violet;
}

.hero-image>h1 {
    font-size: 70px;
    padding-top: 20vh;
    padding-left: 10%;
    font-weight: 900;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #fff;
    line-height: 1.4;
}

.hero-image>h1>label {
    font-size: 17px;
    font-weight: 400;
    color: violet;
    margin-top: 10px;
}

.hero-image>h1>label>a {
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    padding: 10px;
    background-color: transparent;
    border: 2px solid#fff;
    border-radius: 50px;
}

.hero-image>h1>label>a:hover {
    font-size: 17px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    padding: 10px;
    background-color: rgb(87, 4, 196);
    border: 2px solid rgb(87, 4, 196);
    transition: 0.3s;
    border-radius: 50px;
}

body {
    background: #070a0e;
}

.menu-fix {
    width: 100%;
    position: fixed;
    box-shadow: 0 0 15px #000;
}

.nav-link:hover {
    color: thistle !important;
}


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #182029;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: rgba(57, 48, 82, 0.808);
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: rgba(57, 48, 82, 0.623);
    transition: 0.3s;
}

@media (max-width:1367px) {
    .hero-image>h1>b {
        font-size: 20px;
    }
    .hero-image>h1 {
        font-size: 70px;
        padding-top: 40vh;
    }
}

@media (max-width:1024px) {
    .hero-image>h1>b {
        font-size: 20px;
    }
    .hero-image>h1 {
        font-size: 70px;
        padding-top: 30vh;
    }
}

@media (max-width:812px) {
    .hero-image>h1>b {
        font-size: 20px;
    }
    .hero-image>h1 {
        font-size: 30px;
        padding-top: 26vh;
    }
}

@media (max-width:800px) {
    .hero-image>h1>b {
        font-size: 20px;
    }
    .hero-image>h1 {
        font-size: 60px;
        padding-top: 28vh;
    }
    .sec-1>.card {
        width: 350px !important;
        margin: 10px;
    }
}

@media (max-width:736px) {
    .hero-image>h1>b {
        font-size: 20px;
    }
    .hero-image>h1 {
        font-size: 30px;
        padding-top: 28vh;
    }
    .rty {
        margin-top: -10px;
    }
}

@media (max-width:568px) {
    .hero-image>h1>b {
        font-size: 17px;
    }
    .hero-image>h1 {
        font-size: 25px;
        padding-top: 21vh;
        padding-right: 10%;
    }
}

@media (max-width:415px) {
    .hero-image>h1>b {
        font-size: 19px;
    }
    .hero-image>h1 {
        font-size: 35px;
        padding-top: 35vh;
        padding-right: 10%;
    }
}

@media (max-width:400px) {
    .hero-image>h1>b {
        font-size: 19px;
    }
    .hero-image>h1 {
        font-size: 35px;
        padding-top: 35vh;
        padding-right: 10%;
    }
    .sec-1>.card {
        max-width: 310px;
        margin: 10px;
    }
}

@media (max-width:321px) {
    .hero-image>h1>b {
        font-size: 17px;
    }
    .hero-image>h1 {
        font-size: 25px;
        padding-top: 40vh;
        padding-right: 10%;
    }
}

.pages-header {
    height: 65vh;
    padding-top: 70px;
    background-image: url("/assets/img/header.jpg");
    /* The image used */
    background-color: #cccccc;
    /* Used if the image is unavailable */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    opacity: 0.3;
}

.pages-title {
    position: absolute;
    margin-top: -35vh;
    text-align: center;
    display: block;
    width: 100%;
}

.pages-title>h2 {
    color: white;
    font-weight: 900;
    font-size: 60px;
    line-height: 1;
}

.pages-title>b {
    color: white;
    font-weight: 900;
    font-size: 18px;
}

.pages-title>b>a {
    color: white;
    font-weight: 900;
    font-size: 18px;
}